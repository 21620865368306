// Screen Size mixins
@mixin mobile-xs {
	@media (min-width: #{$xs-min}) {
		@content;
	}
}

@mixin mobile-sm {
	@media (min-width: #{$sm-min}) {
		@content;
	}
}

@mixin mobile {
	@media (min-width: #{$sm-min}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$md-min}) {
		@content;
	}
}

@mixin tablet-xl {
	@media (min-width: #{$ml-min}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$lg-min}) {
		@content;
	}
}
@mixin desktop-xl {
	@media (min-width: #{$xl-min}) {
		@content;
	}
}

// General mixins
@mixin applyOverlay {
	background-color: $primary-midnight-900;
	position: fixed;
	inset: 0;
	animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
	opacity: 0.5;
	z-index: 100;
	@content;
}

@mixin regularLink {
	font-size: rem(16);
	font-weight: 600;
	color: $secondary-steel-900;
	background: none;
	border: 1px solid transparent;
	cursor: pointer;
	@content;

	&:hover {
		text-decoration: underline;
		color: $secondary-steel-900;
	}

	&:active {
		color: $primary-midnight-900;
		text-decoration: underline;
	}

	&:focus-within,
	&:focus-visible {
		color: $secondary-steel-900;
		outline: 1px solid $information-300;
		border-color: $information-300;
	}
}

@mixin underlinedLink {
	@include regularLink;
	text-decoration: underline;

	&:hover {
		color: $secondary-steel-500;
	}

	&:active {
		color: $primary-midnight-900;
	}
}

@mixin contained($fullWidthMobile: false) {
	max-width: calc(var(--content-max-width));
	margin: 0 auto;
	width: 100%;

	@if $fullWidthMobile {
		padding-inline: 0;

		@include tablet {
			padding-inline: var(--edge-spacing);
		}
	} @else {
		padding-inline: var(--edge-spacing);
	}
}

@mixin footerLinkPrimary {
	text-decoration: underline 1px rgba(234, 232, 231, 0);
	transition: text-decoration-color 0.3s;
	color: $primary-fog-900;

	&:hover {
		text-decoration-color: rgba(234, 232, 231, 1);
	}

	&:active {
		color: $primary-midnight-300;
		text-decoration-color: $primary-midnight-300;
	}

	&:focus {
		outline: solid 2px $information-300;
	}
}

@mixin footerLinkSecondary {
	color: $secondary-steel-300;
	text-decoration: underline 1px $secondary-steel-300;
	transition: color 0.3s, text-decoration-color 0.3s;

	&:hover {
		color: $secondary-steel-500;
		text-decoration-color: $secondary-steel-500;
	}

	&:active {
		color: $shade-white;
		text-decoration-color: $shade-white;
	}

	&:focus {
		outline: solid 2px $information-300;
	}
}

@mixin inlineLinksList {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;

	li {
		margin: 0;
	}

	li:not(:last-child) {
		&::after {
			content: ' | ';
		}
	}
}

// swiper scroll bar

@mixin swiperScrollBar {
    width: 100%;
    left: 0;
    height: 2px;

    .swiper-scrollbar-drag {
        background-color: $primary-cardinal-900;
    }
    
}
