@mixin h1  {
    font-size: rem(33);
    line-height: 120%;
    margin: 0;
}

@mixin h2  {
    font-size: rem(28);
    font-weight: 400;
    line-height: 150%;
}

@mixin h3  {
    font-size: rem(23);
    font-weight: 400;
    line-height: 150%;
}

@mixin h4  {
    font-size: rem(19);
    font-weight: 400;
    line-height: 150%;
}

@mixin paragraph01 {
    font-size: rem(19);
    font-weight: 400;
    line-height: 150%;
}

@mixin paragraph02 {
    font-size: rem(16);
    font-weight: 400;
    line-height: 150%;
}


@mixin paragraph03 {
    font-size: rem(13);
    font-weight: 400;
    line-height: 150%;
}

@mixin paragraph04 {
    font-size: rem(16);
    font-weight: 600;
    line-height: 150%;
}

@mixin caption {
    font-size: rem(13);
    font-weight: 400;
    line-height: 150%;
}

@mixin footer {
    font-size: rem(11);
    font-weight: 400;
    line-height: 150%;
}
