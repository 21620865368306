@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.headerCorporate {

  background: $primary-fog-900;

  @include tablet {
    border-bottom: 2px solid $primary-cardinal-900;
  }

  .layout {
   @include contained;
    display: flex;
    align-items: center;
    padding: rem(16) rem(8);
    gap: rem(12);
    justify-content: space-between;

    @include tablet {
      align-items: flex-start;
      gap: 0;
      padding: rem(24) rem(32) 0 rem(32);
      flex-direction: column;
    }
    @include tablet-xl {
      padding: 0 rem(60);
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: rem(48);
    }
  }

  .emptySquare {
    width: rem(32);
    @include tablet {
      display: none;
    }
  }
}
