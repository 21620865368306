/* Breakpoints */
$xs-min: 321px;
$sm-min: 551px;
$md-min: 801px;
$ml-min: 1025px;
$lg-min: 1281px;
$xl-min: 1921px;

/* Colors */
$primary-midnight-300: #A6A7A7;
$primary-midnight-500: #7A7B7B;
$primary-midnight-700: #4D4F4F;
$primary-midnight-900: #212323;

$primary-fog-50: #FBFAFA;
$primary-fog-300: #F7F6F5;
$primary-fog-500: #F2F1F1;
$primary-fog-700: #EEEDEC;
$primary-fog-900: #EAE8E7;

$primary-cardinal-300: #F3BAB2;
$primary-cardinal-500: #ED978B;
$primary-cardinal-700: #E77565;
$primary-cardinal-900: #D34430;

$secondary-steel-300: #9EB4C3;
$secondary-steel-500: #6D8EA6;
$secondary-steel-700: #3D6988;
$secondary-steel-900: #0C436A;

$secondary-cloud-300: #D9E9F4;
$secondary-cloud-500: #C7DDEE;
$secondary-cloud-700: #B4D2E9;
$secondary-cloud-900: #A1C7E3;

$secondary-periwinkle-300: #E2EAF6;
$secondary-periwinkle-500: #D4DFF2;
$secondary-periwinkle-700: #C5D5ED;
$secondary-periwinkle-900: #B7CAE9;

$secondary-moss-300: #DFE9E3;
$secondary-moss-500: #CEDED4;
$secondary-moss-700: #BED3C6;
$secondary-moss-900: #AEC8B8;

$information-50: #EFF6FF;
$information-300: #93C5FD;
$information-500: #3B82F6;
$information-700: #1D4ED8;
$information-900: #1E3A8A;

$success-50: #ECFDF5;
$success-300: #6EE7B7;
$success-500: #10B981;
$success-700: #047857;
$success-900: #064E3B;

$warning-50: #FFFBEB;
$warning-300: #FCD34D;
$warning-500: #F59E0B;
$warning-700: #B45309;
$warning-900: #78350F;

$error-50: #FEF2F2;
$error-300: #FCA5A5;
$error-500: #EF4444;
$error-700: #B91C1C;
$error-900: #7F1D1D;

$shade-white: #FFFFFF;
$shade-black: #000000;
