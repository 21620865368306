@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.headerDropdown {
    background-color: $shade-white;
    border: 1px solid $primary-fog-900;
    position: fixed;
    z-index: 2;
    top: 101%;
    left: -102%;
    transition: left var(--menu-open-speed) linear;
    min-width: rem(295);

    @include tablet {
        position: absolute;
        left: 0;
    }
    
    @include tablet-xl {
        top: rem(79);
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding: rem(16) rem(8) rem(16) rem(16) ;

            a {
                @include regularLink;
                color: $primary-midnight-900;
            }
        }
    }
}

.mobileMenu {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100dvw;
    height: 100dvh;
    background: white;

    @include tablet {
      position: unset;
      display: none;
      width: fit-content;
      background: transparent;
      height:unset;
      overflow-y: auto;
    }

    ul {
        list-style: none;
        padding: rem(8) rem(20);
        margin: 0;

        li {
            padding: rem(12) 0;

            a {
                @include regularLink;
                color: $primary-midnight-900;
            }
        }
    }
}

.navHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #EAE8E7;
    padding: rem(16) rem(20);

    svg {
        height: rem(32);
        width: rem(32);
    }
    
    @include tablet {
        display: none;
    }

    .navHeaderBackButton {
        text-decoration: none !important;
        svg {
            transform: rotate(180deg);
            fill: $primary-midnight-900;
            width: rem(16);
            height: rem(16);
        }
        span {
            @include paragraph04;
            color: $primary-midnight-900;
        }
    }
}